import { TOMATO_RED } from '../styles/theme';
import { PINK } from '../styles/theme/propertynews';
import platform from './platform';

const DEFAULT_PROPERTIES = {
  strokeColor: platform.select(TOMATO_RED, PINK),
  strokeWeight: 3,
  fillColor: platform.select(TOMATO_RED, PINK),
  fillOpacity: 0.2,
};

export const OPAQUE_STYLE = {
  strokeWeight: 0,
  fillColor: platform.select(TOMATO_RED, PINK),
  fillOpacity: 0.4,
};

const wrapApiPolygon = (coordinates: object, properties?: object) => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: properties || DEFAULT_PROPERTIES,
        geometry: {
          type: 'MultiPolygon',
          coordinates,
        },
      },
    ],
  };
};

export default wrapApiPolygon;
