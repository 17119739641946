import { laptopMediumMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const MAX_WIDTH = 1360;

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div<{ $overflow?: string; $noMaxWidth?: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.$noMaxWidth ? 'none' : `${MAX_WIDTH}px`)};
  margin: 0 auto;
  padding: 0 ${(props) => (props.$noMaxWidth ? '0' : '30')}px;
  overflow: ${(props) => props.$overflow || 'auto'};

  @media (${laptopMediumMax}) {
    padding: 0 ${(props) => (props.$noMaxWidth ? '0' : '15')}px;
  }
`;
