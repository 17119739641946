import platform from '@propertypal/shared/src/utils/platform';
import styled from 'styled-components';

const SocialLinksContainer = styled.section`
  display: flex;
`;

export const IconLink = styled.a`
  margin-bottom: 10px;
  font-size: 14px;
  display: inline-block;
  margin-right: 15px;

  &:hover img {
    filter: ${platform.select(
      'invert(39%) sepia(72%) saturate(4668%) hue-rotate(4deg) brightness(96%) contrast(101%)',
      'invert(44%) sepia(62%) saturate(3918%) hue-rotate(314deg) brightness(94%) contrast(98%)',
    )};
  }
`;

export default SocialLinksContainer;
