import { createSlice } from '@reduxjs/toolkit';
import { HiddenProperty, HiddenPropertyResult } from '../../types/hidden-properties';
import { RootState } from '../store';

export interface HiddenPropertiesState {
  data: HiddenPropertyResult | null;
  loading: boolean;
  error?: Error;
}

const initialState: HiddenPropertiesState = {
  loading: true,
  data: null,
};

interface SetLoading {
  payload: boolean;
}

interface SetData {
  payload: HiddenPropertyResult;
}

interface SetError {
  payload: Error;
}

export const hiddenPropertiesSlice = createSlice({
  name: 'hiddenProperties',
  initialState,
  reducers: {
    setLoading: (state, action: SetLoading) => {
      state.loading = action.payload;
    },
    setData: (state, action: SetData) => {
      state.data = action.payload;
    },
    setMoreData: (state, action: SetData) => {
      if (state.data) {
        state.data = {
          ...action.payload,
          content: [...state.data.content, ...action.payload.content],
        };
      }
    },
    setError: (state, action: SetError) => {
      state.error = action.payload;
    },
    removeHiddenProperty: (state, action) => {
      const { id, hardDelete } = action.payload;
      if (state.data) {
        const { content } = state.data;
        // find the item we want to remove.
        const foundIndex = content.map((contentItem) => contentItem.property.id).indexOf(id);

        const foundProperty: HiddenProperty = state.data.content[foundIndex];
        // direct replace in the array.
        state.data.content[foundIndex] = foundProperty;

        if (hardDelete) {
          state.data.content.splice(foundIndex, 1);
          state.data.totalGroups -= 1;
          state.data.totalPersonalGroups -= 1;
        }
      }
    },
    clearHiddenProperties: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const { removeHiddenProperty, setLoading, setData, setMoreData, setError, clearHiddenProperties } =
  hiddenPropertiesSlice.actions;

export const selectHiddenProperties = (state: RootState) => state.hiddenProperties;

export default hiddenPropertiesSlice.reducer;
