import axios, { API_URL } from '../../services/axios';
import { NetworkContext } from '../../types/network';
import { PropertySearchResult } from '../../types/propertySearch';
import orderFeaturedProperties, { storeIdOrder } from '../../utils/property/orderFeaturedProperties';
import sanitizeParams from '../../utils/sanitizeParams';
import { getFilters } from '../../utils/search';
import getNlPFilters from '../../utils/search/getNlpFilters';
import shuffle from '../../utils/shuffle';
import { setSearch, setSearchParams, setSearchResultParams, setSearchText, setUseOnLaunch } from '../search';
import { AppThunk } from '../store';
import { pushProperties, setLoading, setProperties, setError, updatePropertiesFavourite } from './properties.slice';

type PropertySearch = (imageLocator: string) => AppThunk;

type PropertyUrlSearch = (imageLocator: string, url: string, ctx?: NetworkContext) => AppThunk<Promise<any>>;

type NextPage = (page: number, imageLocator: string) => AppThunk;

type PropertyTracker = (id: string | number, isDevelopment: boolean, payload: any) => AppThunk<Promise<boolean>>;

type UpdatePropertyFavourites = () => AppThunk;

export const propertyUrlSearch: PropertyUrlSearch = (imageLocator, url, ctx) => async (dispatch, getState) => {
  try {
    sanitizeParams(dispatch, getState);

    const { search } = getState();

    const result = await axios<PropertySearchResult>(
      {
        url: `${API_URL}/search-results?url=${encodeURIComponent(url)}&imageLocator=${encodeURIComponent(
          imageLocator,
        )}`,
      },
      dispatch,
      ctx,
    );

    // @ts-ignore
    delete result.data.searchForm.options;
    // @ts-ignore
    delete result.data.searchForm.lastToken;
    // @ts-ignore
    delete result.data.searchForm.meta;

    result.data.searchForm.phrase = {
      drawnSearch: result.data.searchForm.phrase.drawnSearch,
      travelTimeSearch: result.data.searchForm.phrase.travelTimeSearch,
      asTyped: result.data.searchForm.phrase.asTyped,
      asTypedPristine: result.data.searchForm.phrase.asTypedPristine,
      search: result.data.searchForm.phrase.search,
      words: result.data.searchForm.phrase.words,
      currentLocation: result.data.searchForm.phrase.currentLocation,
      roi: result.data.searchForm.phrase.roi,
      uk: result.data.searchForm.phrase.uk,
    };

    result.data.searchForm.phrase.search = {
      allowExpand: result.data.searchForm.phrase.search.allowExpand,
      allowRadius: result.data.searchForm.phrase.search.allowRadius,
    };

    const { nlpFilters } = getNlPFilters(result.data.params);

    dispatch(
      setSearch({
        text: result.data.queryText || '',
        filters: { ...search.filters, ...nlpFilters },
      }),
    );

    dispatch(setSearchResultParams(result.data.params));
    dispatch(setSearchParams(result.data.searchForm));

    // @ts-ignore
    delete result.data.searchForm;
    // @ts-ignore
    delete result.data.primaryTowns;

    const data = await orderFeaturedProperties(result.data, ctx);

    data.results = data.results.map((property) => {
      if (property.agentLogos && property.agentLogos.length > 9) {
        return {
          ...property,
          agentLogos: shuffle(property.agentLogos),
        };
      }

      return property;
    });

    dispatch(
      setProperties({
        page: 0,
        data,
        loading: false,
      }),
    );

    return result;
  } catch (err: any) {
    dispatch(setError(err));

    return null;
  }
};

export const propertySearch: PropertySearch = (imageLocator) => async (dispatch, getState) => {
  dispatch(
    setProperties({
      page: 0,
      data: undefined,
      loading: true,
    }),
  );

  sanitizeParams(dispatch, getState);

  const { search } = getState();

  try {
    const filtersString = await getFilters(search.filters, dispatch);

    const result = await axios(
      {
        url: `${API_URL}/search-results?q=${encodeURIComponent(
          search.text,
        )}&${filtersString}&imageLocator=${encodeURIComponent(imageLocator)}`,
      },
      dispatch,
    );

    storeIdOrder(result.data.results);

    if (result.data.queryText) dispatch(setSearchText(result.data.queryText));

    dispatch(setSearchResultParams(result.data.params));
    dispatch(setSearchParams(result.data.searchForm));
    dispatch(
      setProperties({
        page: 0,
        data: result.data,
        loading: false,
      }),
    );
    dispatch(setUseOnLaunch(true));
  } catch (err: any) {
    dispatch(setError(err));
  }
};

export const nextPage: NextPage = (page, imageLocator) => async (dispatch, getState) => {
  const { search, properties } = getState();

  if (properties.endReached || properties.loading) {
    return;
  }

  dispatch(setLoading(true));

  const filters = await getFilters(search.filters, dispatch);

  const result = await axios(
    {
      url: `${API_URL}/search-results?q=${encodeURIComponent(search.text)}&${filters}&imageLocator=${encodeURIComponent(
        imageLocator,
      )}`,
      params: {
        page,
      },
    },
    dispatch,
  );

  dispatch(
    pushProperties({
      page,
      data: result.data,
      loading: false,
    }),
  );
};

export const propertyEventTracker: PropertyTracker = (id, isDevelopment, payload) => async (dispatch) => {
  try {
    const urlEndpoint = isDevelopment ? '/development/' : '/property/';
    const result = await axios<any>(
      {
        method: 'POST',
        url: `${API_URL}${urlEndpoint}${id}/event`,
        data: payload,
      },
      dispatch,
    );
    return result.status === 200;
  } catch (err: any) {
    return false;
  }
};

export const updatePropertiesFavourites: UpdatePropertyFavourites = () => async (dispatch, getState) => {
  const { properties } = getState();

  if (properties.data?.results.length) {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/social-group?&view=PERSONAL&type=FAVOURITE`,
      },
      dispatch,
    );

    dispatch(updatePropertiesFavourite(result.data.content));
  }
};
