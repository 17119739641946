import { RootState } from '@propertypal/shared/src/reducers/store';
import { configureGtag, trackPageView } from '@propertypal/shared/src/services/analytics';
import { getItem } from '@propertypal/shared/src/services/storage';
import { GREY_300 } from '@propertypal/shared/src/styles/theme';
import defaultTheme from '@propertypal/shared/src/styles/theme/default';
import propertyNewsTheme, { propertyNewsNavTheme } from '@propertypal/shared/src/styles/theme/propertynews';
import { Property } from '@propertypal/shared/src/types/property';
import { PROFILE_IMAGE } from '@propertypal/shared/src/utils/auth';
import setEmailHashes from '@propertypal/shared/src/utils/liveramp';
import platform from '@propertypal/shared/src/utils/platform';
import PageProgress from '@propertypal/web-ui/src/animation/PageProgress';
import SkipToContent from '@propertypal/web-ui/src/buttons/SkipToContent';
import Header from '@propertypal/web-ui/src/navigation/Header';
import MobileFooter from '@propertypal/web-ui/src/navigation/MobileFooter';
import Tooltip from '@propertypal/web-ui/src/notifications/Tooltip';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useStore } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import config from '../../../../../app.config.json';
import prebid from '../../../public/prebid.lib.js?src';
import quantcast from '../../../public/quantcast.lib.js?src';
import Footer from '../footer/Footer';

interface ListItem {
  url: string;
  label: string;
}

interface SiteMapData {
  parent: ListItem;
  children: ListItem[];
}

interface Props {
  siteMapLinks: SiteMapData[] | null;
  cfIpCountry: string | null;
  loggedIn?: boolean;
  disableHeader?: boolean;
  disableFooter?: boolean;
  children: ReactNode;
  property?: Property;
  emailHashes?: string[];
}

const ToastProvider = dynamic(() => import('@propertypal/web-ui/src/toast/ToastContext'), { ssr: false });

const GlobalStyle = createGlobalStyle`
  body { font-family: ${platform.font} }
  input { font-family: ${platform.font} }
  button { font-family: ${platform.font} }
`;

const Layout: FunctionComponent<Props> = (props) => {
  const router = useRouter();
  const store = useStore<RootState>();
  const theme = config.platform === 'propertynews' ? propertyNewsTheme : defaultTheme;
  const navTheme = platform.select(defaultTheme, propertyNewsNavTheme);

  const updateGtagParams = async () => {
    const { properties } = store.getState();
    const profileImage = await getItem(PROFILE_IMAGE);

    configureGtag({
      logged_in: typeof props.loggedIn === 'boolean' ? props.loggedIn : !!profileImage,
      property_id: props.property?.type === 'property' ? props.property?.id : undefined,
      development_id: props.property?.type === 'development' ? props.property?.id : props.property?.development?.id,
      account_number: props.property?.account?.accountNumber,
      sale_type: props.property?.saleType?.key || properties.data?.gptTargeting?.SaleType[0],
      category: props.property?.propertyType?.key || properties.data?.gptTargeting?.Category[0],
      featured: props.property?.featuredListing,
      premier: props.property?.premiumListing,
    });
    trackPageView(router, props.property);
  };

  // Triggers the CMP on every page
  useEffect(() => {
    updateGtagParams();
  }, [router.asPath]);

  return (
    <>
      <Head>
        {platform.select(
          <link key="icon:favicon" rel="icon" href="/favicon.ico" type="image/x-icon" sizes="any" />,
          <link key="icon:favicon" rel="icon" href="/favicon-pn.ico" type="image/x-icon" sizes="any" />,
        )}
        {platform.select(<link rel="icon" href="/favicon.svg" type="image/svg+xml" />, null)}
        {platform.select(
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />,
          <link rel="apple-touch-icon" href="/apple-touch-icon-pn.png" />,
        )}

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta property="fb:app_id" content={platform.select('120078844683782', '134573973291011')} />
        <meta name="referrer" content="always" />
        <meta name="usr" content={props.cfIpCountry === 'IE' ? 'IRL' : 'GBR'} />
        <meta name="theme-color" content={GREY_300} />
        <meta name="msvalidate.01" content="FF4B57E0082F5B54429F27FFB6C49CB2" />
        <meta itemProp="jedi-mind-trick" content="You will bookmark this site and use it a lot." />
        <meta name="format-detection" content="telephone=no" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={`@${platform.select('propertypal', 'propertynewsni')}`} />
        <meta name="twitter:creator" content={`@${platform.select('propertypal', 'propertynewsni')}`} />
        <meta name="twitter:domain" content={`${config.platform}.com`} />

        <meta property="og:site_name" content={`${config.platform}.com`} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image:user_generated" content="false" />
      </Head>

      <GlobalStyle />

      <ThemeProvider theme={theme}>
        <div style={{ minHeight: '100vh' }}>
          {!props.disableHeader && (
            <>
              <SkipToContent />

              <ThemeProvider theme={navTheme}>
                <Header cfIpCountry={props.cfIpCountry} loggedIn={props.loggedIn} />
              </ThemeProvider>
            </>
          )}

          <div id="main" tabIndex={-1} data-testid="default-layout">
            {props.children}
          </div>

          {!props.disableFooter && (
            <>
              <Footer siteMapData={props.siteMapLinks} cfIpCountry={props.cfIpCountry} />
              <MobileFooter cfIpCountry={props.cfIpCountry} />
            </>
          )}
        </div>

        <div id="modal-root" />

        <PageProgress />
        <Tooltip />
        <ToastProvider />

        <Script strategy="beforeInteractive" key="gaAnalytics">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.googleAnalyticsKey}', {
              send_page_view: false,
            });
          `}
        </Script>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsKey}`}
        />
        <Script
          strategy="afterInteractive"
          src="https://launchpad-wrapper.privacymanager.io/ebb31f85-ef22-4d70-97ff-9a9c1d694324/launchpad-liveramp.js"
          onLoad={() => {
            setEmailHashes(props.emailHashes);
          }}
        />
        <Script strategy="afterInteractive" src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
        <Script strategy="afterInteractive" src={prebid} />

        {config.env !== 'Local' && (
          <>
            <Script strategy="beforeInteractive" src={quantcast} />
            <Script
              strategy="afterInteractive"
              src={`https://www.google.com/recaptcha/enterprise.js?render=${config.recaptchaKey}`}
            />
            <Script strategy="beforeInteractive">
              {`
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:${config.hotjarId},hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
            </Script>
          </>
        )}
      </ThemeProvider>
    </>
  );
};

export default Layout;
