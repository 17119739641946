export const DARK_ORANGE = '#CC4002';
export const TOMATO_RED = '#ee4900';
export const TOMATO_RED_TRANSLUCENT = 'rgba(238,73,0,0.1)';
export const GOLD = '#F3AA11';
export const DARK_GREY_TRANSLUCENT = 'rgba(53, 53, 53, 0.9)';
export const MONZA = '#d0021b';
export const AZALEA = '#F8D3D3';
export const ORANGE = '#f39525';
export const LIGHT_GREY = '#d8d8d8';
export const WHITE = '#ffffff';
export const WHITE_TRANSLUCENT = 'rgba(255, 255, 255, 0.95)';
export const WHITE_100 = '#f5f5f5';
export const WHITE_200 = '#efefef';
export const WHITE_300 = '#cccccc';
export const WHITE_400 = '#d8d8d8';
export const BLACK = '#121212';
export const DEFAULT_BLACK = '#000000';
export const GREY_300 = '#353535';
export const GREY_400 = '#4a4a4a';
export const GREY_500 = '#b8b6b6';
export const GREY_600 = '#666666';
export const GREY_900 = '#999999';
export const PLUM = '#663c51';
export const PURPLE = '#823b8f';
export const RED = '#d0021b';
export const GREEN = '#7dbf7c';
export const BLUE = '#2a5185';
export const FB_BLUE = '#3c5899';
export const FB_MESSENGER_BLUE = '#00C6FF';
export const YOUTUBE_RED = '#FF0000';
export const LINKED_IN_BLUE = '#0e76a8';
export const WHATSAPP_GREEN = '#25D366';
export const GOOGLE_RED = '#de4b39';
export const PROVINCIAL_PINK = '#FDECE5';
export const LIGHT_LIGHT_BLUE = '#F5FAFB';
export const BLUE_LINK = '#B7DDE6';
export const GREEN_LINK = '#7FBF7E';
export const GREY_DIVIDER = '#979797';
export const DARK_BLUE = '#435971';
export const MILLAR_MCCALL_WYLIE = '#da8699';
export const MILLAR_MCCALL_WYLIE_TRANSPARENT = '#fcf5f7';
export const WHITE_MODAL_BG = 'rgba(255, 255, 255, 0.9)';
export const WHITE_MODAL_CONTENT_BG = 'rgba(255, 255, 255, 0.8)';
export const WHITE_MODAL_HEADER_COLOR = GREY_300;
