import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import React, { CSSProperties, FunctionComponent } from 'react';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import IconBox from './Chevron.style';

interface Props {
  containerStyle?: CSSProperties;
  size: number;
  color?: string;
  direction?: 'left' | 'right';
  arrow?: boolean;
}

const Chevron: FunctionComponent<Props> = (props) => {
  return (
    <IconBox style={props.containerStyle}>
      <FontAwesomeIcon
        icon={
          props.direction === 'left'
            ? (props.arrow && faArrowLeft) || faChevronLeft
            : (props.arrow && faArrowRight) || faChevronRight
        }
        style={{ fontSize: props.size }}
        color={props.color}
      />
    </IconBox>
  );
};

export default Chevron;
