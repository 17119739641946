/*
  eslint-disable no-console,
  import/no-mutable-exports,
  unused-imports/no-unused-vars,
  @typescript-eslint/no-unused-vars
*/
import React, { useEffect, useRef, useState } from 'react';
import { Container, Text } from './Tooltip.style';

export let openTooltip = (
  // @ts-ignore
  text: string,
  // @ts-ignore
  event: any,
) => {
  console.warn('Tooltip not initialised');
};

export let closeTooltip = () => {
  console.warn('Tooltip not initialised');
};

interface Rect {
  top: number;
  left: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

const getLeft = (anchor: string, rect: Rect, width: number) => {
  if (anchor === 'left') {
    return rect.left - 5;
  }

  if (anchor === 'right') {
    return rect.left + rect.width - width + 15;
  }

  return rect.left - width / 2 + rect.width / 2;
};

const getAnchor = (rect: Rect | null) => {
  if (rect) {
    if (rect.left < 60) {
      return 'left';
    }

    if (typeof window !== 'undefined' && window.innerWidth - rect.right < 100) {
      return 'right';
    }
  }

  return 'middle';
};

const Tooltip = () => {
  const [text, setText] = useState('');
  const [targetRect, setTargetRect] = useState<DOMRect | null>(null);
  const [boxRect, setBoxRect] = useState<DOMRect | null>(null);
  const anchor = getAnchor(targetRect);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    openTooltip = (mText, event) => {
      setText(mText);
      setTargetRect(null);
      setBoxRect(null);

      setTimeout(() => {
        setTargetRect(event.target.getBoundingClientRect());
        if (containerRef.current) setBoxRect(containerRef.current.getBoundingClientRect());
      }, 100);
    };

    closeTooltip = () => {
      setText('');
    };

    window.addEventListener('resize', closeTooltip);

    return () => {
      window.removeEventListener('resize', closeTooltip);
    };
  }, []);

  if (!text) {
    return null;
  }

  return (
    <Container
      ref={containerRef}
      anchor={anchor}
      style={{
        top: targetRect ? targetRect.top + window.scrollY - (boxRect?.height || 0) - 15 : 0,
        left: targetRect ? getLeft(anchor, targetRect, 180) : 0,
        opacity: targetRect && boxRect ? 1 : 0,
      }}
      data-testid="tooltip"
      data-anchor={anchor}
    >
      <Text>{text}</Text>
    </Container>
  );
};

export default Tooltip;
