import config from '../../../../app.config.json';

interface Platform {
  type: string;
  title: string;
  ordersUrl: string;
  domain: string;
  font: string;
  url: string;
  helpEmail: string;
  select: <T>(propertypal: T, propertynews: T) => T;
}

const getOrdersUrl = () => {
  if (config.platform === 'propertynews') {
    return config.env === 'Develop' ? 'https://orders-dev.propertynews.com' : 'https://orders.propertynews.com';
  }

  return config.env === 'Develop' ? 'https://orders-dev.propertypal.net' : 'https://orders.propertypal.com';
};

const getFullUrl = () => {
  if (config.platform === 'propertynews') {
    if (config.env === 'Develop') return 'https://dev.propertynews.com';
    return 'https://www.propertynews.com';
  }

  if (config.env === 'Develop') return 'https://www-dev.propertypal.net';
  if (config.env === 'Staging') return 'https://demo.propertypal.com';
  return 'https://www.propertypal.com';
};

const platform: Platform = {
  type: config.platform,
  title: config.platform === 'propertynews' ? 'Propertynews' : 'PropertyPal',
  domain: config.platform === 'propertynews' ? 'propertynews.com' : 'propertypal.com',
  font:
    config.platform === 'propertynews'
      ? 'degular, BlinkMacSystemFont, sans-serif'
      : 'Open Sans, Helvetica Neue, sans-serif',
  ordersUrl: getOrdersUrl(),
  url: getFullUrl(),
  helpEmail: config.platform === 'propertynews' ? 'support@propertynews.com' : 'help@propertypal.com',
  select: (propertypal, propertynews) => {
    if (config.platform === 'propertynews') return propertynews;
    return propertypal;
  },
};

export default platform;
