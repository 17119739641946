import {
  GREY_300,
  GREY_400,
  GREY_600,
  GREY_900,
  ORANGE,
  TOMATO_RED,
  TOMATO_RED_TRANSLUCENT,
  DARK_GREY_TRANSLUCENT,
  WHITE,
  WHITE_TRANSLUCENT,
  WHITE_100,
  WHITE_300,
  WHITE_400,
  PLUM,
  BLUE,
  GREEN,
  RED,
  FB_BLUE,
  GOOGLE_RED,
  WHITE_200,
  GREY_500,
  LIGHT_GREY,
  PROVINCIAL_PINK,
  PURPLE,
  LIGHT_LIGHT_BLUE,
  BLUE_LINK,
  GREEN_LINK,
  GREY_DIVIDER,
  DEFAULT_BLACK,
  GOLD,
  DARK_BLUE,
  WHITE_MODAL_BG,
  WHITE_MODAL_CONTENT_BG,
} from './colors';

const defaultTheme = {
  primary: TOMATO_RED,
  primaryTranslucent: TOMATO_RED_TRANSLUCENT,
  secondary: ORANGE,
  background: WHITE,
  backgroundLightest: WHITE_100,
  backgroundLighter: WHITE_200,
  backgroundLight: WHITE_300,
  backgroundMid: GREY_900,
  backgroundDark: GREY_600,
  textDark: GREY_300,
  textMid: GREY_400,
  textLight: GREY_600,
  buttonHover: GREY_500,
  white: WHITE,
  whiteTranslucent: WHITE_TRANSLUCENT,
  darkGrey: GREY_300,
  darkGreyTranslucent: DARK_GREY_TRANSLUCENT,
  lightGrey: LIGHT_GREY,
  purple: PURPLE,
  plum: PLUM,
  blue: BLUE,
  green: GREEN,
  red: RED,
  textOffWhite: WHITE_100,
  facebookBlue: FB_BLUE,
  googleRed: GOOGLE_RED,
  keyline: WHITE_400,
  mortgageSalmon: PROVINCIAL_PINK,
  lightBlue: LIGHT_LIGHT_BLUE,
  linkBlue: BLUE_LINK,
  linkGreen: GREEN_LINK,
  greyDivider: GREY_DIVIDER,
  black: DEFAULT_BLACK,
  gold: GOLD,
  darkBlue: DARK_BLUE,
  modalBg: WHITE_MODAL_BG,
  modalContentBg: WHITE_MODAL_CONTENT_BG,
};

export type Theme = typeof defaultTheme;

export default defaultTheme;
