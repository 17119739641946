import platform from '@propertypal/shared/src/utils/platform';
import config from '../../../../app.config.json';

export interface NavLink {
  text: string;
  title?: string;
  url?: string;
  category?: string; // for search sub links
  saleType?: string; // for search sub links
  Component?: () => JSX.Element[];
  sublinks?: NavLink[];
  testID?: string;
  activeUrls?: string[];
  isNew?: boolean;
}

export const SUB_BUY_LINKS = (isRoi: boolean): NavLink[] => {
  const appendText = isRoi ? 'republic-of-ireland' : 'northern-ireland';

  return [
    {
      text: 'Property For Sale',
      url: `/property-for-sale/${appendText}`,
      category: 'residential',
      saleType: 'sale',
      testID: 'residentialSale',
    },
    {
      text: 'New Homes',
      url: `/new-developments/${appendText}`,
      category: 'newhomes',
      saleType: 'sale',
      testID: 'newhomesSale',
    },
    {
      text: 'Residential Land & Sites',
      url: `/residential-land-for-sale/${appendText}`,
      category: 'land',
      saleType: 'sale',
      testID: 'landSale',
    },
    {
      text: 'Auctions',
      title: 'Residential Auctions',
      url: `/residential-auctions/${appendText}`,
      category: 'auctions',
      saleType: 'sale',
      testID: 'auctionsSale',
    },
  ];
};

export const SUB_RENT_LINKS = (isRoi: boolean): NavLink[] => {
  const appendText = isRoi ? 'republic-of-ireland' : 'northern-ireland';

  return [
    {
      text: 'Property For Rent',
      url: `/property-to-rent/${appendText}`,
      category: 'residential',
      saleType: 'rent',
      testID: 'residentialRent',
    },
    {
      text: 'Student Rentals',
      url: `/student-rentals/${appendText}`,
      category: 'student',
      saleType: 'rent',
      testID: 'studentRent',
    },
    {
      text: 'Holiday Homes',
      url: `/holiday-homes-to-rent/${appendText}`,
      category: 'holidayhomes',
      saleType: 'rent',
      testID: 'holidayhomesRent',
    },
  ];
};

const SUB_COMMERCIAL_LINKS = (isRoi: boolean): NavLink[] => {
  const appendText = isRoi ? 'republic-of-ireland' : 'northern-ireland';

  return [
    {
      text: 'Sales',
      title: 'Commerical Sales',
      url: `/commercial-property-for-sale/${appendText}`,
      saleType: 'sale',
      category: 'commercial',
    },
    {
      text: 'Rentals',
      title: 'Commercial Rentals',
      url: `/commercial-property-to-rent/${appendText}`,
      saleType: 'rent',
      category: 'commercial',
    },
    {
      text: 'Agricultural',
      url: `/agricultural-for-sale/${appendText}`,
      category: 'agricultural',
      saleType: 'sale',
      testID: 'agriculturalSale',
    },
    {
      text: 'Land & Sites',
      url: `/land-for-sale/${appendText}`,
      category: 'land',
      saleType: 'sale',
      testID: 'landSale',
    },
    {
      text: 'Auctions',
      title: 'Commercial Auctions',
      url: `/commercial-auctions/${appendText}`,
      category: 'commercialAuctions',
      saleType: 'sale',
      testID: 'auctionsSale',
    },
  ];
};

export const BUY_LINKS = (isRoi: boolean): NavLink => ({
  text: 'Buy',
  testID: 'buyLink',
  sublinks: SUB_BUY_LINKS(isRoi),
});

export const RENT_LINKS = (isRoi: boolean): NavLink => ({
  text: 'Rent',
  testID: 'rentLink',
  sublinks: SUB_RENT_LINKS(isRoi),
});

export const COMMERCIAL_LINKS = (isRoi: boolean): NavLink => ({
  text: 'Commercial',
  testID: 'commercialSale',
  sublinks: SUB_COMMERCIAL_LINKS(isRoi),
});

export const NEW_HOMES_LINK: NavLink = {
  text: 'New Homes',
  url: '/new-homes/northern-ireland',
};

export const FIND_AGENTS_LINK: NavLink = {
  text: 'Find Agents',
  url: '/find-an-agent',
  activeUrls: [
    '/find-an-agent',
    '/estate-agents',
    '/letting-agents',
    '/student-letting-agents',
    '/commercial-estate-agents',
    '/new-homes-agents',
  ],
  testID: 'findAnAgent',
};

export const NEWS_AND_ANALYSIS_LINK: NavLink = {
  text: 'News & Analysis',
  url: '/news-and-analysis',
  activeUrls: ['/news-and-analysis'],
  testID: 'newsAndAnalysis',
};

type AccountLink = { text: string; url?: string; testID?: string };

const USER_ACCOUNT_LINKS: AccountLink[] = [
  {
    text: 'Account Overview',
    url: '/my/overview',
    testID: 'accountOverview',
  },
  {
    text: 'Favourites',
    url: '/my/favourites',
    testID: 'myFavourites',
  },
  {
    text: 'Saved Searches',
    url: '/my/alerts',
    testID: 'mySavedSearch',
  },
  {
    text: 'My Enquiries',
    url: '/my/enquiries',
    testID: 'myEnquiries',
  },
  {
    text: 'Notes',
    url: '/my/notes',
    testID: 'myNotes',
  },
  {
    text: 'Pals & Sharing',
    url: '/my/pals',
    testID: 'myPals',
  },
];

const PROFILE_LINK: AccountLink = {
  text: 'My Profile',
  url: '/my/profile',
  testID: 'myProfile',
};

const HIDDEN_PROPERTIES_LINK: AccountLink = {
  text: 'Hidden Properties',
  url: '/my/hidden-properties',
  testID: 'myHiddenProperties',
};

const IMPORTANT_PLACE_LINK: AccountLink = {
  text: 'Important Places',
  url: '/my/important-places',
  testID: 'importantPlaces',
};

const LOGOUT_LINK: AccountLink = {
  text: 'Logout',
};

const PP_ACCOUNT_LINKS: AccountLink[] = [...USER_ACCOUNT_LINKS, PROFILE_LINK, LOGOUT_LINK];
const PN_ACCOUNT_LINKS: AccountLink[] = [
  ...USER_ACCOUNT_LINKS,
  HIDDEN_PROPERTIES_LINK,
  IMPORTANT_PLACE_LINK,
  PROFILE_LINK,
  LOGOUT_LINK,
];

export const ACCOUNT_LINKS = platform.select(PP_ACCOUNT_LINKS, PN_ACCOUNT_LINKS);

export const SIGN_IN_LINKS = [
  {
    text: 'Log In',
    url: '/login',
    testID: 'loginLink',
  },
  {
    text: 'Sign Up',
    url: '/register',
  },
];

export const STANDARD_LINKS = (isRoi: boolean) => {
  let links = [];
  if (isRoi) {
    links = [BUY_LINKS(isRoi), RENT_LINKS(isRoi), COMMERCIAL_LINKS(isRoi), FIND_AGENTS_LINK];
  } else links = [BUY_LINKS(isRoi), RENT_LINKS(isRoi), NEW_HOMES_LINK, COMMERCIAL_LINKS(isRoi)];

  if (config.platform !== 'propertynews') links.push(NEWS_AND_ANALYSIS_LINK);

  return links;
};
