import facebookIcon from '@propertypal/shared/src/resources/icons/social/facebook.svg?url';
import instagramIcon from '@propertypal/shared/src/resources/icons/social/instagram.svg?url';
import linkedInIcon from '@propertypal/shared/src/resources/icons/social/linkedin.svg?url';
import tikTokIcon from '@propertypal/shared/src/resources/icons/social/tiktok.svg?url';
import twitterIcon from '@propertypal/shared/src/resources/icons/social/twitter.svg?url';
import platform from '@propertypal/shared/src/utils/platform';
import React from 'react';
import SocialLinksContainer, { IconLink } from './SocialLinks.style';

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <IconLink
        aria-label={`Follow ${platform.title} on Facebook`}
        href={platform.select('https://www.facebook.com/propertypal', 'https://www.facebook.com/PropertynewsNI/')}
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebookIcon.src} alt="facebook" loading="lazy" />
      </IconLink>

      <IconLink
        aria-label={`Follow ${platform.title} on X`}
        href={platform.select('https://x.com/propertypal', 'https://x.com/propertynewsni')}
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitterIcon.src} alt="x" loading="lazy" />
      </IconLink>

      <IconLink
        aria-label={`Follow ${platform.title} on Instagram`}
        href={platform.select('https://www.instagram.com/propertypal', 'https://www.instagram.com/propertynewsni/')}
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagramIcon.src} alt="instagram" loading="lazy" />
      </IconLink>

      <IconLink
        aria-label={`Follow ${platform.title} on LinkedIn`}
        href={platform.select(
          'https://www.linkedin.com/company/propertypal-com',
          'https://www.linkedin.com/company/propertynews.com',
        )}
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedInIcon.src} alt="linkedin" loading="lazy" />
      </IconLink>

      {platform.select(
        <IconLink
          aria-label={`Follow ${platform.title} on tiktok`}
          href="https://www.tiktok.com/@propertypal"
          target="_blank"
          rel="noreferrer"
        >
          <img src={tikTokIcon.src} alt="tiktok" loading="lazy" />
        </IconLink>,
        null,
      )}
    </SocialLinksContainer>
  );
};

export default SocialLinks;
