import defaultTheme from '@propertypal/shared/src/styles/theme/default';
import { GREY_300, WHITE, WHITE_300, WHITE_200 } from './colors';

export const PINK = '#ea4a6d';
export const GREEN = '#00f0b5';
export const WHITE_MODAL_BG = 'rgba(52, 59, 57, 0.95)';
export const WHITE_MODAL_CONTENT_BG = WHITE;

const propertyNewsTheme = {
  ...defaultTheme,
  primary: PINK,
  primaryTranslucent: 'rgba(234, 74, 109, 0.1)',
  secondary: PINK,
  keyline: '#919191',
  green: GREEN,
  modalBg: WHITE_MODAL_BG,
  modalContentBg: WHITE_MODAL_CONTENT_BG,
};

export const propertyNewsNavTheme = {
  ...propertyNewsTheme,
  darkGrey: WHITE,
  textDark: WHITE_300,
  white: GREY_300,
  darkGreyTranslucent: 'rgba(255, 255, 255, 1)',
  textLight: WHITE_200,
};

export default propertyNewsTheme;
